// import React, { Component } from 'react'
// import {Link} from 'gatsby'
// import logo from "../../images/LOGOPWAweb.png"
// import { FaInstagramSquare } from "react-icons/fa"


// import React from 'react'
// import { Navbar, Container } from "react-bootstrap"
// import logo from "../../images/LOGOPWAweb.png"


// export default function Navbar1() {
//   return (
//     <div>
//       <div>
//         <Container>
//           <Navbar bg="light" variant="light">
//             <Navbar.Brand href="#home">
//               <img
//                 alt=""
//                 src={logo}
//                 width="250"
//                 height="100"
//                 className="d-inline-block align-top"
//               />{" "}
//             </Navbar.Brand>
//           </Navbar>
//         </Container>
//       </div>
//     </div>
//   )
// }



               
import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../images/LOGOPWAweb.png"
import { Button} from "react-bootstrap"


export default class Navbar extends Component {
  state = {
    navbarOpen: false,
    css: "collapse navbar-collapse show",
  }
  navbarHandler = () => {
    this.state.navbarOpen
      ? this.setState({
          navbarOpen: false,
          css: "collapse navbar-collapse",
        })
      : this.setState({
          navbarOpen: true,
          css: "collapse navbar-collapse show",
        })
  }
  render() {
    return (
      <nav className="navbar navbar-expand-sm bg-light navbar-light sticky-top navbarSection">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" width={250} height={100} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            aria-label="display menu"
            onClick={this.navbarHandler}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={this.state.css}>
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <Button
                className="nav-link2"
                href="tel:407-668-9983"
                variant="primary"
                aria-label="display number"
              >
                407-668-9983
              </Button>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}


