import React from "react"
import { Container, Row } from "react-bootstrap"
// import {
//   FaPhoneAlt,
//   FaInstagramSquare,
//   FaTwitter,
// } from "react-icons/fa"
// import { MdEmail } from "react-icons/md"
// // import {IconContext} from "react-icons";
// import MailIcon from "@material-ui/icons/Mail"
// import PhoneIcon from "@material-ui/icons/Phone"
// import Link from "@material-ui/core/Link"
// import Icon from "@material-ui/core/Icon"

export default function PreHeaderTop() {
  return (
    <div className="preHeaderWrapper">
      <Container className="preHeaderContainer">
        <Row xs={12} md={12} lg={12}>
          <a className="emailTop" href="mailto:info@logopwa.com">
            Email: info@logopwa.com
          </a>
        </Row>
      </Container>
    </div>
  )
}

//  ;<Link href="mailto:logopwa@gmail.com">Mail</Link>

// //  ;<Link href="#" onClick={preventDefault}>
// //    Link
// //  </Link>

//  ;<IconContext.Provider value={{ size: "2.5rem", className: "react-icons" }}>
//    <MdEmail href="logopwa@gmail.com" alt="_target" color="gray" />
//    <FaPhoneAlt color="green" />
//    <FaInstagramSquare color="black" />
//    <FaTwitter color="lightBlue" />
//  </IconContext.Provider>
